import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import { capitalize, isEmpty } from 'lodash';
import { SerializedError } from '@reduxjs/toolkit';
import { CancelTokenSource } from 'axios';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { StyledChooseVisitForm } from 'containers/newVisit/styles';
import { yupValidate } from 'utils/yupValidate';
import { TextField } from 'components/Forms/TextField/TextField';
import { SingleSelectField } from 'components/Forms/SingleSelectField/SingleSelectField';
import { API } from 'api';
import { workplaceKindsSelector } from 'ducks/workplaces/selectors';
import { getWorkplaceKinds } from 'ducks/workplaces/actions';
import { AsyncSelectField } from 'components/Forms/AsyncSelectField/AsyncSelectField';
import { DatepickerField } from 'components/Forms/DatepickerField/DatepickerField';
import {
  StyledWorkplaceFormButtonsRow,
  StyledWorkplaceFormRow,
  StyledWorkplaceKindRow,
  StyledWorkplaceStreetRow,
  StyledWorkplaceTerminationRow,
} from 'containers/newVisit/shared/workplaceFormStyles';
import { StyledError, StyledOwnerSearchWrapper } from 'containers/newVisit/OrdinaryVisit/styles';
import { FlexContainer, Margin } from 'styles/utils';
import { formatQueryDate } from 'utils/date';
import { TextareaField } from 'components/Forms/TextareaField/TextareaField';
import { SpinnerIcon } from 'utils/iconsMap';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { searchCompanies } from 'containers/newVisit/shared/workplaceFormUtils';
import i18n from 'i18n';
import { chosenDistrictSelector } from 'ducks/powerSearch/selectors';
import { AsyncSelectFieldWithCheckbox } from 'components/Forms/AsyncSelectFieldWithCheckbox/AsyncSelectFieldWithCheckbox';

const t = (text: string) => i18n.t(text);

interface ValuesType {
  comment?: string;
  kind?: { label: string; value: string };
  municipality: { label: string; value: number };
  name?: string;
  post_code?: string | null;
  postal_area_id?: string;
  postal_area_name?: string;
  proprietorship?: { label: string; value: string };
  address?: string;
  termination?: string;
  workplace_id?: string;
}

export interface WorkplaceFormSubmitValues {
  comment: string;
  kind: string;
  municipality_id: number;
  name: string;
  postal_area_id: number | null;
  proprietorship_id: string | null;
  address: string;
  termination: string | null;
}

type PostalCodeResponseType = Readonly<{
  data: {
    code: string;
    id: number;
    municipality: string;
    municipalityId: number;
    name: string;
  };
}>;

interface WorkplaceFormPropTypes {
  Buttons?: (props: {
    onClick: () => void;
    isPrimaryDisabled: boolean;
    setHaveOwner: React.Dispatch<React.SetStateAction<boolean>>;
  }) => JSX.Element | null;
  buttonsPosition?: 'inside' | 'outside';
  formId?: string;
  init?: Partial<ValuesType & { id: string }>;
  isCommentEditable?: boolean;
  isDisabled?: boolean;
  onSubmit: (values: WorkplaceFormSubmitValues) => Promise<unknown>;
}

export const WorkplaceForm = (props: WorkplaceFormPropTypes) => {
  const {
    Buttons,
    buttonsPosition = 'inside',
    formId,
    init,
    isCommentEditable = true,
    isDisabled,
  } = props;
  const dispatch = useDispatch();
  const [haveOwner, setHaveOwner] = useState<boolean>(!!init?.proprietorship?.value);
  const [searchInactive, setSearchInactive] = useState(false);
  const workplaceKinds = useSelector(workplaceKindsSelector);
  const chosenDistrict = useSelector(chosenDistrictSelector);
  const ownerCancelToken = useRef<CancelTokenSource | null>(null);
  const searchMunicipalities = useCallback(async (query: string) => {
    const response = await API.get(`/municipalities?query=${query}`);
    return response.data.data.tableBody.map(({ name, id }: { name: string; id: string }) => ({
      label: name,
      value: id,
    }));
  }, []);

  useEffect(() => {
    isEmpty(workplaceKinds) && dispatch(getWorkplaceKinds());
  }, []);

  const onSubmit = useCallback(
    async (values: ValuesType) => {
      const {
        kind,
        name,
        address,
        postal_area_id,
        municipality,
        termination,
        proprietorship,
        comment,
      } = values;

      const valuesToPost = {
        kind: kind?.value || '',
        name: name || '',
        address: address?.replace(/\s+/g, ' ').trim() || '',
        postal_area_id: postal_area_id ? Number(postal_area_id) : null,
        municipality_id: municipality.value,
        termination: formatQueryDate(termination),
        proprietorship_id: (haveOwner && proprietorship?.value) || null,
        comment: comment || '',
      };
      return props
        .onSubmit(valuesToPost)
        .catch((err: SerializedError) => ({ [FORM_ERROR]: err.message }));
    },
    [props.onSubmit, haveOwner],
  );

  const postCodeChange = useCallback(async (value: string, formOnChange: Function) => {
    formOnChange('post_code', value);
    if (value.length === 4) {
      try {
        const response: PostalCodeResponseType = await API.get(`/post_codes/${value}`);
        const { id, municipality, name, municipalityId } = response.data;
        formOnChange('postal_area_name', name);
        formOnChange('postal_area_id', id);
        formOnChange('municipality', { label: municipality, value: municipalityId });
      } catch (error) {
        formOnChange('postal_area_name', '');
        formOnChange('postal_area_id', undefined);
      }
    } else {
      formOnChange('postal_area_name', '');
      formOnChange('postal_area_id', undefined);
      formOnChange('municipality', { label: '', value: undefined });
    }
  }, []);

  if (isEmpty(workplaceKinds))
    return (
      <FlexContainer justifyContent='center' margin='2.4rem'>
        <SpinnerIcon />
      </FlexContainer>
    );
  return (
    <>
      <Form
        initialValues={(init || {}) as unknown as ValuesType}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, values, submitError, errors }) => (
          <StyledChooseVisitForm id={formId} onSubmit={handleSubmit}>
            <StyledWorkplaceFormButtonsRow>
              <StyledWorkplaceKindRow>
                <Field
                  component={SingleSelectField}
                  isDisabled={isDisabled}
                  label={capitalize(t('forms.type'))}
                  name='kind'
                  options={workplaceKinds}
                  required
                  size='small'
                  subscription={{ value: true, touched: true, error: true }}
                  withClearButton
                />
                <Field
                  additionalInputProps={{ vsize: 'small' }}
                  component={TextField}
                  isDisabled={isDisabled}
                  label={t('forms.name')}
                  name='name'
                  required
                  subscription={{ value: true, touched: true, error: true }}
                />
                <Field
                  additionalInputProps={{ vsize: 'small' }}
                  component={AsyncSelectField}
                  fetchFunction={searchMunicipalities}
                  isDisabled={!!values.postal_area_name || isDisabled}
                  label={t('forms.municipality')}
                  name='municipality'
                  required
                  subscription={{ value: true, touched: true, error: true }}
                  withClearButton
                />
              </StyledWorkplaceKindRow>
            </StyledWorkplaceFormButtonsRow>

            <StyledWorkplaceStreetRow>
              <Field
                additionalInputProps={{ vsize: 'small' }}
                component={TextField}
                isDisabled={isDisabled}
                label={t('company_details.basic-data.address')}
                name='address'
                required
              />

              <Field
                additionalInputProps={{ vsize: 'small' }}
                component={TextField}
                inputExtra={{
                  maxLength: 4,
                  inputType: 'numeric',
                  onChange: ({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => {
                    postCodeChange(value, form.change);
                  },
                  value: values.post_code,
                }}
                isDisabled={isDisabled}
                label={t('forms.post_code')}
                name='post_code'
                subscription={{ value: true, touched: true, error: true }}
              />
              <Field
                additionalInputProps={{ vsize: 'small' }}
                component={TextField}
                isDisabled
                label={t('forms.post_area')}
                name='postal_area_name'
              />
            </StyledWorkplaceStreetRow>

            <StyledWorkplaceTerminationRow>
              <Field
                component={DatepickerField}
                isDisabled={isDisabled}
                label={t('forms.termination')}
                name='termination'
                size='small'
              />
              <Field
                component={TextareaField}
                isDisabled={isDisabled || !isCommentEditable}
                label={t('common.comment')}
                minRows={1}
                name='comment'
                vsize='small'
              />
            </StyledWorkplaceTerminationRow>

            <StyledWorkplaceFormRow>
              <Checkbox
                isChecked={haveOwner}
                isDisabled={isDisabled}
                label={t('forms.owner')}
                onClick={() => setHaveOwner((x) => !x)}
              />
              {haveOwner && (
                <StyledOwnerSearchWrapper>
                  <Field
                    additionalInputProps={{ vsize: 'small' }}
                    component={AsyncSelectFieldWithCheckbox}
                    fetchFunction={(text: string) =>
                      searchCompanies(
                        text,
                        {
                          'filter[district_names][values]': chosenDistrict,
                          'filter[status][values]': searchInactive
                            ? ['active', 'inactive']
                            : 'active',
                        },
                        500,
                        ownerCancelToken,
                      )
                    }
                    id='searchOwner'
                    isChecked={searchInactive}
                    isDisabled={isDisabled}
                    labelCheckbox={t('new_visits.include_inactive')}
                    name='proprietorship'
                    setSearchInactive={() => setSearchInactive((x) => !x)}
                    subscription={{ value: true, touched: true, error: true }}
                    withClearButton
                  />
                </StyledOwnerSearchWrapper>
              )}
              {Buttons && buttonsPosition === 'inside' && (
                <Buttons
                  isPrimaryDisabled={!isEmpty(errors)}
                  onClick={form.submit}
                  setHaveOwner={setHaveOwner}
                />
              )}
            </StyledWorkplaceFormRow>
            {submitError && (
              <Margin margin='1.2rem auto'>
                <StyledError>{submitError}</StyledError>
              </Margin>
            )}
            {Buttons && buttonsPosition === 'outside' && (
              <Buttons
                isPrimaryDisabled={!isEmpty(errors)}
                onClick={form.submit}
                setHaveOwner={setHaveOwner}
              />
            )}
          </StyledChooseVisitForm>
        )}
        validate={isDisabled ? undefined : yupValidate(schema, { context: { haveOwner } })}
      />
    </>
  );
};

const schema = yup.object().shape({
  kind: yup.object().nullable().required(),
  name: yup.string().required(),
  address: yup.string().required().nullable(),
  municipality: yup
    .object()
    .required()
    .test(
      'municipalityTest',
      t('forms.required_municipality_postal'),
      //@ts-ignore
      function (mun: { label: string; value: string } | null) {
        return !mun?.value ? false : true;
      },
    ),
  post_code: yup
    .string()
    .nullable()
    .test(
      'hasPostnummerOrMunicipality',
      t('forms.required_municipality_postal'),
      //@ts-ignore
      function (value: string) {
        return (!value && !this.parent.municipality?.value) ||
          (value?.length === 4 && !this.parent.municipality?.value)
          ? false
          : true;
      },
    ),
  proprietorship: yup.mixed().when('$haveOwner', {
    is: true,
    then: yup.object().nullable().required(),
    otherwise: undefined,
  }),
});
