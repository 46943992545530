import styled, { css } from 'styled-components/macro';

import { CheckboxSizes } from 'components/Checkbox/Checkbox';

export const StyledCheckbox = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;

  input[type='checkbox'] {
    position: absolute;
    visibility: hidden;
    z-index: -1;

    &:checked + div {
      background-color: ${({ theme }) => theme.colors.blue500};
      border: none;
      box-shadow: ${({ theme }) => theme.boxShadows.blue300};
    }

    &:disabled + div {
      background-color: ${({ theme }) => theme.colors.black50};
      border: none;
      box-shadow: none;

      svg {
        & path {
          fill: ${({ theme }) => theme.colors.black300};
        }
      }
    }

    &:disabled + div,
    &:disabled ~ label {
      color: ${({ theme }) => theme.colors.black300};
      pointer-events: none;
    }
  }
`;

interface StyledBoxPropTypes {
  isIndeterminate?: boolean;
  size: CheckboxSizes;
}

export const StyledBox = styled.div<StyledBoxPropTypes>`
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: 1px solid ${({ theme }) => theme.colors.black300};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  ${({ theme, size }) => {
    switch (size) {
      case 'small':
        return theme.icon.sm;
      case 'medium':
        return theme.icon.md;
      case 'large':
        return theme.icon.lg;
    }
  }};

  & svg {
    ${({ theme, size }) => {
      switch (size) {
        case 'small':
          return theme.icon.sm;
        case 'medium':
          return theme.icon.md;
        case 'large':
          return theme.icon.lg;
      }
    }};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue50};
    border-color: ${({ theme }) => theme.colors.blue300};
  }

  ${({ isIndeterminate }) =>
    isIndeterminate &&
    css`
      && {
        background-color: ${({ theme }) => theme.colors.blue500};
        border-color: transparent;
        box-shadow: ${({ theme }) => theme.boxShadows.blue300};
      }
    `}
`;

export const StyledCheckboxLabel = styled.label<{
  color?: string;
  htmlFor?: string;
  onClick?: () => void;
}>`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${({ theme }) => theme.sizes.xs};
  margin-right: ${({ theme }) => theme.sizes.sm};
  padding: ${({ theme }) => theme.sizes.xxs} 0;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

export const StyledCheckboxMainLabel = styled.div`
  ${({ theme }) => theme.fontSizes.base};
`;

export const StyledCheckboxAdditionalLabel = styled.div`
  ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.black200};
`;
